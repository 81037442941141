import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "body" ]

  connect() {
    this.element[this.identifier] = this
    this.parent = this.element.closest('.reviewable')

    this.render()

    this.boundUpdate = window.debounce(this.update, 100).bind(this)
    this.observer = new MutationObserver(this.boundUpdate)
    this.observer.observe(this.parent, { childList: true, subtree: true })
  }

  disconnect() {
    this.observer.disconnect()
  }

  update(mutations) {
    var hasUpdates = false
    for (var i = 0; i < mutations.length; i++) {
      if (mutations[i].target.classList.contains('reviewer-comments')) {
        hasUpdates = true
        break
      }
    }
    if (hasUpdates) {
      this.render()
    }
  }

  render() {
    self = this
    var comments = this.parent.querySelectorAll('.reviewer-comment:not(.reviewer-comment-resolved)')
    this.bodyTarget.innerHTML = ''
    var comment_ids = []
    comments.forEach(function(comment) {
      if (comment_ids.includes(comment.dataset.elementId)) {
        return
      }
      comment_ids.push(comment.dataset.elementId)
      var link = document.createElement('a')
      link.href = '#' + comment.dataset.elementId
      link.innerHTML = comment.dataset.elementName
      link.dataset.turbo = 'false'
      var item = document.createElement('li')
      item.className = 'mini-list-item'
      item.appendChild(link)
      self.bodyTarget.appendChild(item)
    })

    if (comment_ids.length > 0) {
      this.element.classList.remove('d-none')
    }
    else {
      this.element.classList.add('d-none')
    }
  }
}
